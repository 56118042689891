exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-lms-js": () => import("./../../../src/pages/lms.js" /* webpackChunkName: "component---src-pages-lms-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-portfolio-list-js": () => import("./../../../src/templates/portfolio-list.js" /* webpackChunkName: "component---src-templates-portfolio-list-js" */),
  "component---src-templates-portfolio-post-js": () => import("./../../../src/templates/portfolio-post.js" /* webpackChunkName: "component---src-templates-portfolio-post-js" */),
  "component---src-templates-testimonials-list-js": () => import("./../../../src/templates/testimonials-list.js" /* webpackChunkName: "component---src-templates-testimonials-list-js" */),
  "component---src-templates-testimonials-post-js": () => import("./../../../src/templates/testimonials-post.js" /* webpackChunkName: "component---src-templates-testimonials-post-js" */)
}


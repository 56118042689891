import './src/styles/main.scss';

// import 'jquery/dist/jquery.min.js';
// import 'bootstrap-sass/assets/javascripts/bootstrap.min.js';
if (typeof window !== 'undefined') {
	window.jQuery = window.$ = require('jquery');
	require('bootstrap-sass/assets/javascripts/bootstrap.min.js');
}

// export const onRouteUpdate = ({ location, prevLocation }) => {
// 	console.log('new pathname', location.pathname);
// 	console.log('old pathname', prevLocation ? prevLocation.pathname : null);
// };
